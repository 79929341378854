import { SupportedPlatforms } from './platforms';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiEndpoint: 'https://cspa-dev.callanonline.com',
  serverBase: 'https://stage.cspa.callanworks.co.uk',
  authClientId: 'adi9we0fh8w9ehfAUWIDJW9wfeewnfiwuhf9sadb',
  authEndpoint: 'https://casa-dev.callanonline.com',
  awsBase: 'https://s3.amazonaws.com',
  platform: SupportedPlatforms.web,
  // should not be used in env different than developer
  casaTestingEndpoint: null,
  debug: true,
  envName: "sate"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
