import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
declare var $: any;

class SelectionItem{
  constructor(public value: string) {}
}

@Component({
  selector: 'app-ios-drag-and-drop',
  templateUrl: './ios-drag-and-drop.component.html',
  styleUrls: ['./ios-drag-and-drop.component.scss']
})
export class IosDragAndDropComponent implements OnInit, AfterViewInit {


  @ViewChild("screen", {static: true})
  screen: ElementRef ;
  @ViewChild("shelf", {static: true})
  shelf: ElementRef;

  @ViewChild("t1", {static: true})
  t1: ElementRef ;
  @ViewChild("t2", {static: true})
  t2: ElementRef ;
  @ViewChild("t3", {static: true})
  t3: ElementRef ;
  @ViewChild("t4", {static: true})
  t4: ElementRef ;

  shelfItems = [new SelectionItem("how it goes"), new SelectionItem("no"), new SelectionItem("you’re"),
    new SelectionItem("a lot"), new SelectionItem("along"), new SelectionItem("from"),
    new SelectionItem("straight on"), new SelectionItem("my"), new SelectionItem("take"),
    new SelectionItem("you"), new SelectionItem("do"), new SelectionItem("excuse"),
    new SelectionItem("turn"), new SelectionItem("the way"), new SelectionItem("forwards"),
    new SelectionItem("away"), new SelectionItem("listen to"), new SelectionItem("would") ];
  private withActivation = true;


  constructor(route: ActivatedRoute) {
    this.withActivation = route.snapshot.data['withActivation'];
  }

  targetElements: HTMLElement[] ;

  ngOnInit() {

  }

  _inDragMode = false;

  get isInDragMode(): boolean {
    if (this.withActivation) {
      return this._inDragMode;
    }
    return !!this.dragItem;
  }

  itemClicked() {
    if (this.withActivation) {
      this._inDragMode = !this._inDragMode;
    }
    //this.isInDragMode = !this.isInDragMode;
  }

  dragItem: SelectionItem;
  dragHtmlElement: HTMLElement;
  dragOffsetX = 0;
  dragOffsetY = 0;

  mouseDownOnElement(event: MouseEvent, item: SelectionItem, itemView: HTMLElement) {
    this.onElementDown(event.clientX, event.clientY, event.offsetX, event.offsetY, item, itemView);
  }

  pointerDownOnElement(event: PointerEvent, item: SelectionItem, itemView: HTMLElement) {
    this.onElementDown(event.clientX, event.clientY, event.offsetX, event.offsetY, item, itemView);
  }

  onElementDown(x: number,y: number, offsetX: number, offsetY:number, item: SelectionItem, itemView: HTMLElement) {
    if (this.withActivation && !this.isInDragMode) return;
    // prevent from double pointer event
    if (this.dragItem == item) return;
    this.dragItem = item;
    const elementCopy = itemView.cloneNode(true) as HTMLElement;
    this.dragHtmlElement = elementCopy;
    this.dragOffsetX = offsetX;
    this.dragOffsetY = offsetY;

    elementCopy.style.position = "absolute";
    elementCopy.style.transition = "unset";
    elementCopy.style.pointerEvents = "none";
    this.positionElement(elementCopy, x, y);
    this.screen.nativeElement.appendChild(elementCopy);
  }

  mouseMove(event: MouseEvent) {
    this.onMove(event.clientX, event.clientY);
  }

  pointerMove(event: PointerEvent) {
    if (!this.isInDragMode || !this.dragHtmlElement) return;
    this.onMove(event.clientX, event.clientY);
    event.preventDefault();
  }

  onMove(x: number, y: number) {
    if (!this.isInDragMode || !this.dragHtmlElement) return;
    this.positionElement(this.dragHtmlElement, x, y);
  }

  onUp() {
    if (!this.isInDragMode) return;
    // prevent from double invoke from pointer
    if (!this.dragItem) return;
    if (!this.activeSlots.find(slot => !!slot) && !this.isOverShelf) {
      // no active slots to place, clear everything
      this.screen.nativeElement.removeChild(this.dragHtmlElement);
      this.dragItem = null;
      return;
    }

    if (this.isOverShelf) {
      const isFromShelf = this.shelfItems.indexOf(this.dragItem) >= 0;
      this.screen.nativeElement.removeChild(this.dragHtmlElement);
      const item = this.dragItem;
      this.dragItem = null;
      if (isFromShelf) return;
      const itemSlotPos = this.slotItems.indexOf(item);
      this.slotItems[itemSlotPos] = null;
      this.shelfItems.splice(0,0, item);
    }

    // put in correct place
    for (let i = 0; i < this.activeSlots.length; i++) {
      if (!this.activeSlots[i]) continue;
      const itemToPlace = this.activeSlots[i];
      const prevItem = this.slotItems[i];
      const isFromShelf = this.shelfItems.indexOf(itemToPlace) >= 0;
      if (isFromShelf) {
        const shelfPos = this.shelfItems.indexOf(itemToPlace);
        this.shelfItems.splice(shelfPos, 1);
        if (prevItem) {
          // put prev the item back to the shelf
          this.shelfItems.splice(0, 0, prevItem);
        }
        this.slotItems[i] = itemToPlace;
        this.activeSlots[i] = null;
      } else {
        const currentItemSlotNumber = this.slotItems.indexOf(itemToPlace);
        this.slotItems[currentItemSlotNumber] = prevItem;
        this.slotItems[i] = itemToPlace;
        this.activeSlots[i] = null;
      }
    }
    this.screen.nativeElement.removeChild(this.dragHtmlElement);
    this.dragItem = null;
  }

  private positionElement(el: HTMLElement, clientX: number, clientY: number) {
    const x = clientX - this.dragOffsetX;
    const y = clientY - this.dragOffsetY;
    el.style.left = `${x}px`;
    el.style.top = `${y}px`;
  }

  activeSlots: SelectionItem[] = [null, null, null, null];
  slotItems: SelectionItem[] = [];

  enterTarget(nb: number) {
    if (!this.isInDragMode) return;
    this.activeSlots[nb] = this.dragItem;
  }

  leaveTarget(nb: number) {
    if (!this.isInDragMode) return;
    this.activeSlots[nb] = null;
  }

  isSlotActive(nb: number) {
    return !!this.activeSlots[nb];
  }

  getItem(nb: number): SelectionItem {
    return this.slotItems[nb];
  }

  isOverShelf = false;
  enterShelf() {
    this.isOverShelf = true;
  }

  leaveShelf() {
    this.isOverShelf = false;
  }

  touchMove(event: TouchEvent) {
    if (!this.isInDragMode || !this.dragHtmlElement) return;
    const x = event.touches[0].clientX;
    const y = event.touches[0].clientY;
    this.onMove(x,y);
    event.preventDefault();

    for (let i = 0; i < this.activeSlots.length; i++) {
      const targetElement = this.targetElements[i];
      if (!targetElement) continue;
      const bounds = targetElement.getBoundingClientRect();
      if (x >= bounds.left && x <= bounds.right && y >= bounds.top && y <= bounds.bottom) {
        this.activeSlots[i] = this.dragItem;
      } else {
        this.activeSlots[i] = null;
      }
    }

    if (y >= this.shelf.nativeElement.getBoundingClientRect().top) {
      this.isOverShelf = true;
    } else {
      this.isOverShelf = false;
    }
  }

  ngAfterViewInit(): void {
    this.targetElements = [this.t1.nativeElement, this.t2.nativeElement, this.t3.nativeElement, this.t4.nativeElement];
    console.log(this.targetElements);
  }
}
