/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./darg-and-drop.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./darg-and-drop.component";
var styles_DargAndDropComponent = [i0.styles];
var RenderType_DargAndDropComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DargAndDropComponent, data: {} });
export { RenderType_DargAndDropComponent as RenderType_DargAndDropComponent };
function View_DargAndDropComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [["class", "item"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { selected: 0 }), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "item"; var currVal_1 = _ck(_v, 3, 0, _co.isSelected(_co.getSlotItem(0))); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.getSlotItem(0).value; _ck(_v, 4, 0, currVal_2); }); }
function View_DargAndDropComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [["class", "item"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { selected: 0 }), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "item"; var currVal_1 = _ck(_v, 3, 0, _co.isSelected(_co.getSlotItem(1))); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.getSlotItem(1).value; _ck(_v, 4, 0, currVal_2); }); }
function View_DargAndDropComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [["class", "item"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { selected: 0 }), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "item"; var currVal_1 = _ck(_v, 3, 0, _co.isSelected(_co.getSlotItem(2))); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.getSlotItem(2).value; _ck(_v, 4, 0, currVal_2); }); }
function View_DargAndDropComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [["class", "item"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { selected: 0 }), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "item"; var currVal_1 = _ck(_v, 3, 0, _co.isSelected(_co.getSlotItem(3))); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.getSlotItem(3).value; _ck(_v, 4, 0, currVal_2); }); }
function View_DargAndDropComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [["i", 1]], null, 4, "span", [["class", "item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectFromShelf(_v.context.$implicit, i1.ɵnov(_v, 0)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { selected: 0, "ready-to-place": 1 }), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "item"; var currVal_1 = _ck(_v, 3, 0, _co.isSelected(_v.context.$implicit), _co.shelfItemMayBeReplaced()); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.value; _ck(_v, 4, 0, currVal_2); }); }
export function View_DargAndDropComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { screen: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["screen", 1]], null, 49, "div", [["id", "screen"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 40, "div", [["id", "work-area"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["This is a context sentence."])), (_l()(), i1.ɵeld(5, 0, null, null, 15, "h3", [["class", "my-5"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["This a target "])), (_l()(), i1.ɵeld(7, 0, [["p1", 1]], null, 5, "span", [["class", "drag-target"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectSlot(0, i1.ɵnov(_v, 7)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(9, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(10, { "empty": 0, "ready-to-place": 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DargAndDropComponent_1)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, [" for drag and drop "])), (_l()(), i1.ɵeld(14, 0, [["p2", 1]], null, 5, "span", [["class", "drag-target"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectSlot(1, i1.ɵnov(_v, 14)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(16, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(17, { "empty": 0, "ready-to-place": 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DargAndDropComponent_2)), i1.ɵdid(19, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["."])), (_l()(), i1.ɵeld(21, 0, null, null, 3, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Here is some more text to "])), (_l()(), i1.ɵeld(23, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" make a space for working area scrolling."])), (_l()(), i1.ɵeld(25, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."])), (_l()(), i1.ɵeld(27, 0, null, null, 15, "h3", [["class", "my-5"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["This a target "])), (_l()(), i1.ɵeld(29, 0, [["p3", 1]], null, 5, "span", [["class", "drag-target"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectSlot(2, i1.ɵnov(_v, 29)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(31, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(32, { "empty": 0, "ready-to-place": 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DargAndDropComponent_3)), i1.ɵdid(34, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, [" for drag and drop "])), (_l()(), i1.ɵeld(36, 0, [["p4", 1]], null, 5, "span", [["class", "drag-target"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectSlot(3, i1.ɵnov(_v, 36)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(38, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(39, { "empty": 0, "ready-to-place": 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DargAndDropComponent_4)), i1.ɵdid(41, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["."])), (_l()(), i1.ɵeld(43, 0, null, null, 7, "div", [["id", "shelf"]], null, null, null, null, null)), (_l()(), i1.ɵeld(44, 0, null, null, 6, "div", [["class", "items"]], null, null, null, null, null)), (_l()(), i1.ɵeld(45, 0, [["emptyItem", 1]], null, 3, "span", [["class", "item empty ready-to-place"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.returnToShelf(i1.ɵnov(_v, 45)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(47, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(48, { hide: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DargAndDropComponent_5)), i1.ɵdid(50, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "drag-target"; var currVal_1 = _ck(_v, 10, 0, _co.isSlotEmpty(0), _co.mayBePlacedInSlot(0)); _ck(_v, 9, 0, currVal_0, currVal_1); var currVal_2 = _co.getSlotItem(0); _ck(_v, 12, 0, currVal_2); var currVal_3 = "drag-target"; var currVal_4 = _ck(_v, 17, 0, _co.isSlotEmpty(1), _co.mayBePlacedInSlot(1)); _ck(_v, 16, 0, currVal_3, currVal_4); var currVal_5 = _co.getSlotItem(1); _ck(_v, 19, 0, currVal_5); var currVal_6 = "drag-target"; var currVal_7 = _ck(_v, 32, 0, _co.isSlotEmpty(2), _co.mayBePlacedInSlot(2)); _ck(_v, 31, 0, currVal_6, currVal_7); var currVal_8 = _co.getSlotItem(2); _ck(_v, 34, 0, currVal_8); var currVal_9 = "drag-target"; var currVal_10 = _ck(_v, 39, 0, _co.isSlotEmpty(3), _co.mayBePlacedInSlot(3)); _ck(_v, 38, 0, currVal_9, currVal_10); var currVal_11 = _co.getSlotItem(3); _ck(_v, 41, 0, currVal_11); var currVal_12 = "item empty ready-to-place"; var currVal_13 = _ck(_v, 48, 0, !_co.mayReturnSelectedToShelf()); _ck(_v, 47, 0, currVal_12, currVal_13); var currVal_14 = _co.shelfItems; _ck(_v, 50, 0, currVal_14); }, null); }
export function View_DargAndDropComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-darg-and-drop", [], null, null, null, View_DargAndDropComponent_0, RenderType_DargAndDropComponent)), i1.ɵdid(1, 114688, null, 0, i3.DargAndDropComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DargAndDropComponentNgFactory = i1.ɵccf("app-darg-and-drop", i3.DargAndDropComponent, View_DargAndDropComponent_Host_0, {}, {}, []);
export { DargAndDropComponentNgFactory as DargAndDropComponentNgFactory };
