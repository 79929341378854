/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ios-drag-and-drop.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./ios-drag-and-drop.component";
import * as i4 from "@angular/router";
var styles_IosDragAndDropComponent = [i0.styles];
var RenderType_IosDragAndDropComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IosDragAndDropComponent, data: {} });
export { RenderType_IosDragAndDropComponent as RenderType_IosDragAndDropComponent };
function View_IosDragAndDropComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [["i1", 1]], null, 4, "span", [["class", "item"]], null, [[null, "click"], [null, "mousedown"], [null, "pointerdown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.itemClicked() !== false);
        ad = (pd_0 && ad);
    } if (("mousedown" === en)) {
        var pd_1 = (_co.mouseDownOnElement($event, _co.getItem(0), i1.ɵnov(_v, 0)) !== false);
        ad = (pd_1 && ad);
    } if (("pointerdown" === en)) {
        var pd_2 = (_co.pointerDownOnElement($event, _co.getItem(0), i1.ɵnov(_v, 0)) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { jingle: 0 }), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "item"; var currVal_1 = _ck(_v, 3, 0, _co.isInDragMode); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.getItem(0).value; _ck(_v, 4, 0, currVal_2); }); }
function View_IosDragAndDropComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [["i2", 1]], null, 4, "span", [["class", "item"]], null, [[null, "click"], [null, "mousedown"], [null, "pointerdown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.itemClicked() !== false);
        ad = (pd_0 && ad);
    } if (("mousedown" === en)) {
        var pd_1 = (_co.mouseDownOnElement($event, _co.getItem(1), i1.ɵnov(_v, 0)) !== false);
        ad = (pd_1 && ad);
    } if (("pointerdown" === en)) {
        var pd_2 = (_co.pointerDownOnElement($event, _co.getItem(1), i1.ɵnov(_v, 0)) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { jingle: 0 }), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "item"; var currVal_1 = _ck(_v, 3, 0, _co.isInDragMode); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.getItem(1).value; _ck(_v, 4, 0, currVal_2); }); }
function View_IosDragAndDropComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [["i3", 1]], null, 4, "span", [["class", "item"]], null, [[null, "click"], [null, "mousedown"], [null, "pointerdown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.itemClicked() !== false);
        ad = (pd_0 && ad);
    } if (("mousedown" === en)) {
        var pd_1 = (_co.mouseDownOnElement($event, _co.getItem(2), i1.ɵnov(_v, 0)) !== false);
        ad = (pd_1 && ad);
    } if (("pointerdown" === en)) {
        var pd_2 = (_co.pointerDownOnElement($event, _co.getItem(2), i1.ɵnov(_v, 0)) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { jingle: 0 }), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "item"; var currVal_1 = _ck(_v, 3, 0, _co.isInDragMode); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.getItem(2).value; _ck(_v, 4, 0, currVal_2); }); }
function View_IosDragAndDropComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [["i4", 1]], null, 4, "span", [["class", "item"]], null, [[null, "click"], [null, "mousedown"], [null, "pointerdown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.itemClicked() !== false);
        ad = (pd_0 && ad);
    } if (("mousedown" === en)) {
        var pd_1 = (_co.mouseDownOnElement($event, _co.getItem(3), i1.ɵnov(_v, 0)) !== false);
        ad = (pd_1 && ad);
    } if (("pointerdown" === en)) {
        var pd_2 = (_co.pointerDownOnElement($event, _co.getItem(3), i1.ɵnov(_v, 0)) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { jingle: 0 }), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "item"; var currVal_1 = _ck(_v, 3, 0, _co.isInDragMode); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.getItem(3).value; _ck(_v, 4, 0, currVal_2); }); }
function View_IosDragAndDropComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [["itemView", 1]], null, 4, "span", [["class", "item"]], null, [[null, "click"], [null, "mousedown"], [null, "pointerdown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.itemClicked() !== false);
        ad = (pd_0 && ad);
    } if (("mousedown" === en)) {
        var pd_1 = (_co.mouseDownOnElement($event, _v.context.$implicit, i1.ɵnov(_v, 0)) !== false);
        ad = (pd_1 && ad);
    } if (("pointerdown" === en)) {
        var pd_2 = (_co.pointerDownOnElement($event, _v.context.$implicit, i1.ɵnov(_v, 0)) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { jingle: 0 }), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "item"; var currVal_1 = _ck(_v, 3, 0, _co.isInDragMode); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.value; _ck(_v, 4, 0, currVal_2); }); }
export function View_IosDragAndDropComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { screen: 0 }), i1.ɵqud(402653184, 2, { shelf: 0 }), i1.ɵqud(402653184, 3, { t1: 0 }), i1.ɵqud(402653184, 4, { t2: 0 }), i1.ɵqud(402653184, 5, { t3: 0 }), i1.ɵqud(402653184, 6, { t4: 0 }), (_l()(), i1.ɵeld(6, 0, [[1, 0], ["screen", 1]], null, 51, "div", [["id", "screen"]], null, [[null, "mouseup"], [null, "mousemove"], [null, "touchend"], [null, "touchcancel"], [null, "touchmove"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseup" === en)) {
        var pd_0 = (_co.onUp() !== false);
        ad = (pd_0 && ad);
    } if (("mousemove" === en)) {
        var pd_1 = (_co.mouseMove($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (_co.onUp() !== false);
        ad = (pd_2 && ad);
    } if (("touchcancel" === en)) {
        var pd_3 = (_co.onUp() !== false);
        ad = (pd_3 && ad);
    } if (("touchmove" === en)) {
        var pd_4 = (_co.touchMove($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 43, "div", [["id", "work-area"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(9, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(10, { lock: 0 }), (_l()(), i1.ɵeld(11, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["This is a context sentence."])), (_l()(), i1.ɵeld(13, 0, null, null, 15, "h3", [["class", "my-5"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["This a target "])), (_l()(), i1.ɵeld(15, 0, [[3, 0], ["t1", 1]], null, 5, "span", [["class", "drag-target empty"]], null, [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.enterTarget(0) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.leaveTarget(0) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(17, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(18, { "active": 0, "empty": 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IosDragAndDropComponent_1)), i1.ɵdid(20, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, [" for drag and drop "])), (_l()(), i1.ɵeld(22, 0, [[4, 0], ["t2", 1]], null, 5, "span", [["class", "drag-target empty"]], null, [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.enterTarget(1) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.leaveTarget(1) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(24, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(25, { "active": 0, "empty": 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IosDragAndDropComponent_2)), i1.ɵdid(27, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["."])), (_l()(), i1.ɵeld(29, 0, null, null, 3, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Here is some more text to "])), (_l()(), i1.ɵeld(31, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" make a space for working area scrolling."])), (_l()(), i1.ɵeld(33, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."])), (_l()(), i1.ɵeld(35, 0, null, null, 15, "h3", [["class", "my-5"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["This a target "])), (_l()(), i1.ɵeld(37, 0, [[5, 0], ["t3", 1]], null, 5, "span", [["class", "drag-target empty"]], null, [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.enterTarget(2) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.leaveTarget(2) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(39, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(40, { "active": 0, "empty": 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IosDragAndDropComponent_3)), i1.ɵdid(42, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, [" for drag and drop "])), (_l()(), i1.ɵeld(44, 0, [[6, 0], ["t4", 1]], null, 5, "span", [["class", "drag-target empty"]], null, [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.enterTarget(3) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.leaveTarget(3) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(46, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(47, { "active": 0, "empty": 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IosDragAndDropComponent_4)), i1.ɵdid(49, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["."])), (_l()(), i1.ɵeld(51, 0, [[2, 0], ["shelf", 1]], null, 6, "div", [["id", "shelf"]], null, [[null, "mouseenter"], [null, "mouseleave"], [null, "pointerenter"], [null, "pointerleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.enterShelf() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.leaveShelf() !== false);
        ad = (pd_1 && ad);
    } if (("pointerenter" === en)) {
        var pd_2 = (_co.enterShelf() !== false);
        ad = (pd_2 && ad);
    } if (("pointerleave" === en)) {
        var pd_3 = (_co.leaveShelf() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(53, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(54, { lock: 0 }), (_l()(), i1.ɵeld(55, 0, null, null, 2, "div", [["class", "items"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IosDragAndDropComponent_5)), i1.ɵdid(57, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 10, 0, _co.isInDragMode); _ck(_v, 9, 0, currVal_0); var currVal_1 = "drag-target empty"; var currVal_2 = _ck(_v, 18, 0, _co.isSlotActive(0), !_co.getItem(0)); _ck(_v, 17, 0, currVal_1, currVal_2); var currVal_3 = _co.getItem(0); _ck(_v, 20, 0, currVal_3); var currVal_4 = "drag-target empty"; var currVal_5 = _ck(_v, 25, 0, _co.isSlotActive(1), !_co.getItem(1)); _ck(_v, 24, 0, currVal_4, currVal_5); var currVal_6 = _co.getItem(1); _ck(_v, 27, 0, currVal_6); var currVal_7 = "drag-target empty"; var currVal_8 = _ck(_v, 40, 0, _co.isSlotActive(2), !_co.getItem(2)); _ck(_v, 39, 0, currVal_7, currVal_8); var currVal_9 = _co.getItem(2); _ck(_v, 42, 0, currVal_9); var currVal_10 = "drag-target empty"; var currVal_11 = _ck(_v, 47, 0, _co.isSlotActive(3), !_co.getItem(3)); _ck(_v, 46, 0, currVal_10, currVal_11); var currVal_12 = _co.getItem(3); _ck(_v, 49, 0, currVal_12); var currVal_13 = _ck(_v, 54, 0, _co.isInDragMode); _ck(_v, 53, 0, currVal_13); var currVal_14 = _co.shelfItems; _ck(_v, 57, 0, currVal_14); }, null); }
export function View_IosDragAndDropComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ios-drag-and-drop", [], null, null, null, View_IosDragAndDropComponent_0, RenderType_IosDragAndDropComponent)), i1.ɵdid(1, 4308992, null, 0, i3.IosDragAndDropComponent, [i4.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IosDragAndDropComponentNgFactory = i1.ɵccf("app-ios-drag-and-drop", i3.IosDragAndDropComponent, View_IosDragAndDropComponent_Host_0, {}, {}, []);
export { IosDragAndDropComponentNgFactory as IosDragAndDropComponentNgFactory };
