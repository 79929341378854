<div #screen id="screen"
     (mouseup)="onUp()"
     (mousemove)="mouseMove($event)"
     (touchend)="onUp()"
     (touchcancel)="onUp()"
     (touchmove)="touchMove($event)"
>
  <div id="work-area"
       [ngClass]="{lock: isInDragMode}"
  >
    <h3>This is a context sentence.</h3>
    <h3 class="my-5">This a target
      <span class="drag-target empty"
            #t1
            (mouseenter)="enterTarget(0)"
            (mouseleave)="leaveTarget(0)"
            [ngClass]="{'active': isSlotActive(0) , 'empty': !getItem(0)}"
      >
        <span class="item" *ngIf="getItem(0)"
              #i1
              [ngClass]="{jingle: isInDragMode}"
              (click)="itemClicked()"
              (mousedown)="mouseDownOnElement($event, getItem(0), i1)"
              (pointerdown)="pointerDownOnElement($event, getItem(0), i1)"
        >{{getItem(0).value}}</span>
      </span>
      for drag and drop
      <span class="drag-target empty"
            #t2
            (mouseenter)="enterTarget(1)"
            (mouseleave)="leaveTarget(1)"
            [ngClass]="{'active': isSlotActive(1) , 'empty': !getItem(1)}"
      >
        <span class="item" *ngIf="getItem(1)"
              #i2
              [ngClass]="{jingle: isInDragMode}"
              (click)="itemClicked()"
              (mousedown)="mouseDownOnElement($event, getItem(1), i2)"
              (pointerdown)="pointerDownOnElement($event, getItem(1), i2)"
        >{{getItem(1).value}}</span>
      </span>.</h3>
    <h3>Here is some more text to <br> make a space for working area scrolling.</h3>
    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
    <h3 class="my-5">This a target
      <span class="drag-target empty"
            #t3
            (mouseenter)="enterTarget(2)"
            (mouseleave)="leaveTarget(2)"
            [ngClass]="{'active': isSlotActive(2) , 'empty': !getItem(2)}"
      >
<span class="item" *ngIf="getItem(2)"
      #i3
      [ngClass]="{jingle: isInDragMode}"
      (click)="itemClicked()"
      (mousedown)="mouseDownOnElement($event, getItem(2), i3)"
      (pointerdown)="pointerDownOnElement($event, getItem(2), i3)"
>{{getItem(2).value}}</span>
      </span>
      for drag and drop
      <span class="drag-target empty"
            #t4
            (mouseenter)="enterTarget(3)"
            (mouseleave)="leaveTarget(3)"
            [ngClass]="{'active': isSlotActive(3) , 'empty': !getItem(3)}"
      >
<span class="item" *ngIf="getItem(3)"
      #i4
      [ngClass]="{jingle: isInDragMode}"
      (click)="itemClicked()"
      (mousedown)="mouseDownOnElement($event, getItem(3), i4)"
      (pointerdown)="pointerDownOnElement($event, getItem(3), i4)"
>{{getItem(3).value}}</span>
      </span>.</h3>
  </div>
  <div id="shelf"
       #shelf
       [ngClass]="{lock: isInDragMode}"
       (mouseenter)="enterShelf()"
       (mouseleave)="leaveShelf()"
       (pointerenter)="enterShelf()"
       (pointerleave)="leaveShelf()"
  >
    <div class="items">

      <span class="item"
            #itemView
            [ngClass]="{jingle: isInDragMode}"
            *ngFor="let item of shelfItems"
            (click)="itemClicked()"
            (mousedown)="mouseDownOnElement($event, item, itemView)"
            (pointerdown)="pointerDownOnElement($event, item, itemView)"

      >{{item.value}}</span>
    </div>
  </div>
</div>
