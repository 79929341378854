import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';

declare var $: any;

class SelectionItem{
  constructor(public value: string) {}
}

@Component({
  selector: 'app-darg-and-drop',
  templateUrl: './darg-and-drop.component.html',
  styleUrls: ['./darg-and-drop.component.scss']
})
export class DargAndDropComponent implements OnInit {

  @ViewChild("screen", {static: false})
  screen: ElementRef;

  shelfItems = [new SelectionItem("how it goes"), new SelectionItem("no"), new SelectionItem("you’re"),
  new SelectionItem("a lot"), new SelectionItem("along"), new SelectionItem("from"),
  new SelectionItem("straight on"), new SelectionItem("my"), new SelectionItem("take"),
  new SelectionItem("you"), new SelectionItem("do"), new SelectionItem("excuse"),
  new SelectionItem("turn"), new SelectionItem("the way"), new SelectionItem("forwards"),
  new SelectionItem("away"), new SelectionItem("listen to"), new SelectionItem("would") ];

  slots = [];

  constructor() { }
  shelfSelected: SelectionItem = null;
  slotsSelected: SelectionItem = null;
  selectedItemHtmlElement = null;

  ngOnInit() {
  }

  isSlotEmpty(nb: number) {
    return !this.slots[nb];
  }

  selectFromShelf(item: SelectionItem, element: HTMLSpanElement) {
    //console.log(element);
    if (this.slotsSelected) {
      this.animateMove(this.selectedItemHtmlElement, element);
      this.animateMove(element, this.selectedItemHtmlElement);

      setTimeout(_ => {
        const selectedItemSlotNumber = this.slots.indexOf(this.slotsSelected);
        this.slots[selectedItemSlotNumber] = item;
        this.shelfItems.splice(this.shelfItems.indexOf(item), 1, this.slotsSelected);
        this.slotsSelected = null;
      }, this._timeout);

      return;
    }
    if (this.shelfSelected == item) this.shelfSelected = null;
    else {
      this.shelfSelected = item;
      this.selectedItemHtmlElement = element;
    }
  }

  getScreenTopPosition(element: HTMLElement) {
    if (!element || element.id === 'screen') return 0;
    return element.offsetTop + this.getScreenTopPosition(element.parentElement);
  }

  getScreenLeftPosition(element: HTMLElement) {
    if (!element) return 0;
    return element.offsetLeft + this.getScreenLeftPosition(element.parentElement);
  }

  private animateMove(from: HTMLElement, to: HTMLElement) {
    const fromX = from.getBoundingClientRect().left;
    const fromY = from.getBoundingClientRect().top;

    const toX = to.getBoundingClientRect().left;
    const toY = to.getBoundingClientRect().top;

    const animationElement = from.cloneNode(true) as HTMLElement;
    from.style.opacity = "0";
    from.style.transition = '0.0s';

    animationElement.style.position = 'fixed';
    animationElement.style.left = `${fromX}px`;
    animationElement.style.top = `${fromY}px`;
    animationElement.style.transition = '0.5s';
    this.screen.nativeElement.appendChild(animationElement);
    setTimeout(_ => {
      animationElement.style.left = `${toX}px`;
      animationElement.style.top = `${toY}px`;
      animationElement.style.transform = 'scale(1)';

    },1);
    setTimeout(_ => {
      this.screen.nativeElement.removeChild(animationElement);
      from.style.opacity = "1";
      from.style.transition = '0.5s';
    },500);
  }

  mayReturnSelectedToShelf() {
    return !!this.slotsSelected;
  }

  isSelected(item: SelectionItem) {
    return this.shelfSelected == item || this.slotsSelected == item;
  }

  hasSelectedItem() {
    return this.shelfSelected || this.slotsSelected;
  }

  mayBePlacedInSlot(nb: number) {
    return (this.hasSelectedItem() && (this.isSlotEmpty(nb) || this.slots[nb] != this.getSelectedItem()));
  }

  private getSelectedItem() {
    if (this.shelfSelected) return this.shelfSelected;
    return this.slotsSelected;
  }

  selectSlot(nb: number, parent: HTMLSpanElement) {
    if (this.shelfSelected) {
      const isReplace = !!this.slots[nb];
      this.animateMove(this.selectedItemHtmlElement, parent);
      if (isReplace) this.animateMove(parent.children[0] as HTMLElement, this.selectedItemHtmlElement);
      setTimeout(_ => {
        const replaceWith: SelectionItem[] = [];
        if (this.slots[nb]) {
          replaceWith.push(this.slots[nb]);
          this.slots[nb] = null;
        }
        this.slots[nb] = this.shelfSelected;
        this.shelfItems.splice(this.shelfItems.indexOf(this.shelfSelected),1, ...replaceWith);
        this.shelfSelected = null;
      }, this._timeout);
      return;
    }
    if (this.slotsSelected == this.slots[nb]) {
      this.slotsSelected = null;
      return;
    }
    if (this.slotsSelected) {
      const isReplace = !!this.slots[nb];
      this.animateMove(this.selectedItemHtmlElement, parent);
      if (isReplace) this.animateMove(parent.children[0] as HTMLElement, this.selectedItemHtmlElement);
      setTimeout(_ => {
        const selectedItemSlotNumber = this.slots.indexOf(this.slotsSelected);
        this.slots[selectedItemSlotNumber] = this.slots[nb];
        this.slots[nb] = this.slotsSelected;
        this.slotsSelected = null;
      }, this._timeout);
      return;
    }
    if (!this.slots[nb]) return;
    this.slotsSelected = this.slots[nb];
    this.selectedItemHtmlElement = parent.children[0] as HTMLElement;
  }

  getSlotItem(nb: number): SelectionItem {
    return this.slots[nb];
  }

  shelfItemMayBeReplaced() {
    return this.slotsSelected;
  }

  private readonly _timeout = 400;

  returnToShelf(target: HTMLElement) {
    this.animateMove(this.selectedItemHtmlElement, target);
    setTimeout(_ => {
      this.shelfItems.splice(0, 0, this.slotsSelected);
      const selectedItemSlotNumber = this.slots.indexOf(this.slotsSelected);
      this.slots[selectedItemSlotNumber] = null;
      this.slotsSelected = null;
    }, this._timeout);
  }
}
