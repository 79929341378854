import { Routes } from '@angular/router';
import { OauthComponent } from './pages/oauth/oauth.component';
import { AuthGuardService } from './services/auth-guard.service';
import { ExercisesComponent } from './pages/exercises/exercises.component';
import { ExerciseSetHostComponent } from './components/exercise-set-host/exercise-set-host.component';
import { StartExerciseComponent } from './pages/start-exercise/start-exercise.component';
import { SessionHostComponent } from './components/session-host/session-host.component';
import { SessionQuestionHostComponent } from './components/session-question-host/session-question-host.component';
import { SessionSummaryComponent } from './pages/session-summary/session-summary.component';
import { MenuLayoutComponent } from './layout/menu-layout/menu-layout.component';
import { HomeComponent } from './pages/home/home.component';
import { TokenAuthComponent } from './pages/token-auth/token-auth.component';
import { CfkHomeComponent } from './pages/cfk-home/cfk-home.component';
import { MobileStartExerciseComponent } from './mobile/pages/mobile-start-exercise/mobile-start-exercise.component';
import { MobileSessionHostComponent } from './mobile/components/mobile-session-host/mobile-session-host.component';
import { MobileSessionQuestionHostComponent } from './mobile/components/mobile-session-question-host/mobile-session-question-host.component';
import { MobileSessionSummaryComponent } from './mobile/pages/mobile-session-summary/mobile-session-summary.component';
import { EntryPointExerciseSetComponent } from "./pages/entry-point-exercise-set/entry-point-exercise-set.component";
import { CloseComponent } from "./pages/close/close.component";
import { DargAndDropComponent } from "./pages/prototypes/darg-and-drop/darg-and-drop.component";
import { IosDragAndDropComponent } from "./pages/prototypes/ios-drag-and-drop/ios-drag-and-drop.component";
const ɵ0 = { withActivation: true }, ɵ1 = { withActivation: false };
const routes = [
    // prototypes
    { path: 'drag-drop', component: DargAndDropComponent },
    { path: 'drag-drop-2', component: IosDragAndDropComponent, data: ɵ0 },
    { path: 'drag-drop-3', component: IosDragAndDropComponent, data: ɵ1 },
    // mobile route back compatibility
    { path: 'cspa/startExercise/:exerciseId', component: MobileStartExerciseComponent },
    { path: 'cspa/session/:sessionId', component: MobileSessionHostComponent, children: [
            { path: 'question/:questionNumber', component: MobileSessionQuestionHostComponent },
            { path: 'summary', component: MobileSessionSummaryComponent }
        ] },
    // entry points
    { path: 'entry-point/exercises/:exerciseSet', component: EntryPointExerciseSetComponent },
    // public entry points
    { path: 'close', component: CloseComponent },
    { path: 'oauth', component: OauthComponent },
    { path: 'token-auth', component: TokenAuthComponent },
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    { path: 'home', component: HomeComponent },
    { path: 'kids', component: CfkHomeComponent },
    // protected resources
    {
        path: '',
        canActivate: [AuthGuardService],
        component: MenuLayoutComponent,
        children: [
            { path: 'exercises', component: ExercisesComponent },
            { path: 'exercises/:setPath', component: ExerciseSetHostComponent },
            { path: 'exercises/:exerciseSet/:chapter/:section/:exercise/start', component: StartExerciseComponent },
            { path: 'exercises/:exerciseSet/:chapter/:section/:exercise/session/:sessionId',
                component: SessionHostComponent,
                children: [
                    { path: 'question/:questionNumber', component: SessionQuestionHostComponent },
                    { path: 'summary', component: SessionSummaryComponent }
                ] },
        ]
    },
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1 };
